import Multiselect from 'react-widgets/Multiselect'
import { ListItem } from './ListItem'
import { useAppSelector, useAppDispatch } from './hooks'

import { query, searchPending, searchResults } from './ducks'

export function App() {
  const busy = useAppSelector(searchPending)
  const results = useAppSelector(searchResults)
  const dispatch = useAppDispatch()
  const searchTrigger = (searchTerm: string) => {
    if (searchTerm && searchTerm.length > 2) {
      dispatch(query(searchTerm))
    }
  }

  return (
    <>
      <Multiselect
        busy={busy}
        data={results}
        dataKey="id"
        filter={false}
        textField="name"
        renderListItem={ListItem}
        showSelectedItemsInList={true}
        onSearch={searchTrigger}
      />
    </>
  )
}