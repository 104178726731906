import { createSlice, ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit'
import type { RootState, AppDispatch } from './store'
import { Character, Info } from 'rickmortyapi' 

interface SearchState {
  term: string,
  loading: boolean,
  results: Character[]
}

type EmphasisStartIdx = number
type EmphasisEndIdx = number
type Emphasis = [EmphasisStartIdx, EmphasisEndIdx]

export interface CharacterWithEmphasis extends Character {
  em: Emphasis
}

type CharacterSorting = (x: Character, y: Character) => number
type HighlighterFactory = (searchTerm: string) => Highlighter
type Highlighter = (x: Character) => CharacterWithEmphasis

const initialState: SearchState = {
  term: "",
  loading: false,
  results: []
}

const byId: CharacterSorting = (characterOne, characterTwo) => characterOne.id - characterTwo.id
const addEmphasis: HighlighterFactory = (term) => (character) => {
  const termStart = character.name.toUpperCase().indexOf(term.toUpperCase())
  const termEnd = termStart + term.length
  return {
    ...character,
    em: [termStart, termEnd]
  }
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    query: (state, action: PayloadAction<string>) => {
      state.term = action.payload
      state.results = []
      state.loading = true
    },
    partialResult: (state, action: PayloadAction<Character[]>) => {
      const emphasize = addEmphasis(state.term)
      const newResultsWithEmphasis = action.payload.map(emphasize)
      // We'll want sort these since the potentially parallel requests can be fulfilled in any order
      const results = [...state.results, ...newResultsWithEmphasis].sort(byId)
      state.results = results
    },
    fullResult: (state, action: PayloadAction<Character[]>) => {
      const emphasize = addEmphasis(state.term)
      state.results = action.payload.map(emphasize)
      state.loading = false
    },
    endOp: (state) => {
      state.loading = false
    }
  }
})

const { query, partialResult, fullResult, endOp } = searchSlice.actions
const searchPending = (state: RootState) => state.search.loading
const searchResults = (state: RootState) => state.search.results

const searchApi = {
  actionCreator: query,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    await listenerApi.delay(50);
    const { signal } = listenerApi;
    const initialQuery = await fetch(`https://rickandmortyapi.com/api/character/?name=${action.payload}`, { signal })
    const initialQueryJson: Info<Character[]> = await initialQuery.json()
    if (initialQueryJson.info.pages === 1) {
      // This is the entire search results
      listenerApi.dispatch(fullResult(initialQueryJson.results))
    } else if (initialQueryJson.info.pages === 2) {
      // We will need to do exactly 1 follow-up request
      listenerApi.dispatch(partialResult(initialQueryJson.results))
      const page2 = await fetch(`https://rickandmortyapi.com/api/character/?name=${action.payload}&page=2`, { signal })
      const page2Json: Info<Character[]> = await page2.json()
      listenerApi.dispatch(partialResult(page2Json.results))
      listenerApi.dispatch(endOp())
    } else {
      // We will need to do n additional requests, better do them in parallel
      const paginationTasks = Array.from({ length: initialQueryJson.info.pages - 1 }, (_, idx) => {
        const page = idx + 2
        const worker = listenerApi.fork(async () => {
          const partialResp = await fetch(`https://rickandmortyapi.com/api/character/?name=${action.payload}&page=${page}`, { signal })
          const partialJson = await partialResp.json()
          listenerApi.dispatch(partialResult(partialJson.results))
        })
        return worker.result
      })
      await Promise.all(paginationTasks).then(() => listenerApi.dispatch(endOp()))
    }
  }
}

export default searchSlice.reducer
export { query, searchPending, searchResults, searchApi }