import { CharacterWithEmphasis } from './ducks'

interface ListItemProps {
  item: CharacterWithEmphasis
}

export function ListItem({ item }: ListItemProps) {
  const preEm = item.name.substring(0, item.em[0])
  const em = item.name.substring(...item.em)
  const postEm = item.name.substring(item.em[1])
  return (
    <>
      <input className='checked' type='checkbox' role='presentation' checked readOnly />
      <input className='unchecked' type='checkbox' role='presentation' readOnly />
      <img loading='lazy' src={item.image} height="70" width="70" />
      <p>{preEm}<strong>{em}</strong>{postEm}</p>
      <p>{item.episode.length} Episodes</p>
      
    </>
  )
}