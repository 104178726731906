import { configureStore, TypedAddListener, addListener, createAsyncThunk } from '@reduxjs/toolkit'
import ApiMiddleware from './apiMiddleware'
import searchSlice, { searchApi } from './searchSlice'
import apiMiddleware from './apiMiddleware'

export const store = configureStore({
  reducer: {
    search: searchSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(ApiMiddleware.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const addAppListener: TypedAddListener<RootState, AppDispatch> = addListener

store.dispatch(addAppListener(searchApi))