import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { store } from './ducks'
import { App } from "./App";

const container = document.getElementById("app");
if (container) {
  const root = createRoot(container)
  root.render(<Provider store={store}>
    <App />
  </Provider>);
}